@import 'styles/breakpoints';

.root {
  padding-top: 0;
}

.card {
  text-align: center;
  background-color: var(--bg-layer);
  padding: var(--spacing-500) var(--spacing-400);
  border: none;

  @media screen and (min-width: $widthTablet) {
    padding: var(--spacing-700) var(--spacing-600);
  }

  p {
    margin: 0 0 var(--spacing-500) 0;
  }
}
