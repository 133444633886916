@import 'styles/breakpoints';

// Steps Card
.steps {
  display: grid;
  gap: var(--spacing-500);
  list-style-type: none;
  padding: 0;
  margin: var(--spacing-600) 0 0 0;
}
.step {
  display: grid;
  gap: var(--spacing-500);
  grid-template-columns: min-content 1fr;

  &.centered {
    align-items: center;
  }

  @media screen and (min-width: $widthTablet) {
    background-color: var(--bg-layer);
    padding: var(--spacing-600);
  }
}

.step-rw {
  background-color: var(--accent-yellow-subdued);
  box-shadow: 0 0 0 var(--spacing-400) var(--accent-yellow-subdued);
  margin-top: var(--spacing-200);

  @media screen and (min-width: $widthTablet) {
    box-shadow: none;
    margin-top: 0;
  }
}

.stepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--text-default);
  background-color: var(--bg-primary);
  font-size: var(--text-lg-font-size);
  line-height: var(--text-lg-line-height);
  font-weight: 900;
  border-radius: 50%;
}
.stepBody {
  display: grid;
  gap: var(--spacing-100);
}
