@import 'styles/breakpoints';

.faq-section {
  padding-top: var(--spacing-900);
  padding-bottom: var(--spacing-900);

  @include until($widthTablet) {
    padding-top: var(--spacing-600);
    padding-bottom: var(--spacing-600);
  }

  @include until($widthMobile) {
    padding: 40px 0 30px;
  }

  .search {
    width: 580px;
    margin: auto;

    @include until($widthMobile) {
      width: 100%;
    }
  }

  .faq-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .button-container {
    text-align: center;

    button {
      font-size: var(--text-sm-font-size);
      line-height: var(--text-sm-line-height);
      min-width: 180px;
      height: 50px;

      @include until($widthMobile) {
        height: 42px;
      }
    }
  }
}
