@import 'styles/breakpoints';

.hero-title {
  max-width: 460px
}

.action-title {
  font-size: var(--heading-lg-font-size);
  line-height: var(--heading-lg-line-height);
}

.action-section {
  background-color: var(--accent-yellow);
  min-height: 70vh;

  .action-image {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    object-position: center right;
  }

  .timer {
    display: flex;
    align-items: center;
    gap: var(--spacing-100);
    font-weight: 900;
    margin-bottom: var(--spacing-400);

    @media screen and (min-width: $widthTablet) {
      margin-bottom: var(--spacing-500);
    }
  }

  .more-info-btn {
    background-color: var(--white);
    width: fit-content;
  }
}

.drawer {
  display: grid;
  align-content: flex-start;
  font-weight: 400;

  :is(h1, p) {
    margin: 0 0 var(--spacing-500);
  }

  :is(h2, ul, ol, li) {
    margin: 0 0 var(--spacing-300);
  }

  :is(ul, ol) {
    list-style-type: square;
    padding-left: var(--spacing-400);

    li::marker {
      color: var(--text-primary);
    }
  }
}

.relativePosition {
  position: relative;

  .startSimulationForm {
    @include until($widthTablet) {
      position: static;
      transform: none;
      width: 100%;
    }
  }
}

.heroImage {
  object-position: top center;
}
