@import 'styles/breakpoints';

// Form
.form {
  display: grid;
  gap: var(--spacing-500);
  margin-top: var(--spacing-400);
}

.formField {
  display: grid;
  gap: var(--spacing-200);
}

.errorMsg {
  margin: 0;
}

.submitButton {
  justify-self: flex-start;
}

// Referral link copy
.referralLinkContainer {
  display: grid;
}
.referralLinkLayout {
  display: grid;
  grid-template-columns: 1fr max-content;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Errors
.errorContainer {
  display: grid;
  gap: var(--spacing-500);
}

// Internal Error
.internalError {
  border-color: var(--bg-critical);
  margin: 0;
  line-height: 1.5;
}
.internalErrorButton {
  justify-self: flex-start;
}

// Share buttons
.share {
  font-weight: 600;
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);
}

.linkCardShare {
  display: grid;
  gap: var(--spacing-200) 0;
}
.shareButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-200);
}
.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: var(--radius-sm);
  background: linear-gradient(-270deg, #55e1c9 0%, #46f9c0 100%);
  color: #141a57;

  &:hover:not(:disabled) {
    background: linear-gradient(to right, #2c439e, #100061);
    color: var(--white);
  }
}
